import { graphql, useStaticQuery } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import React, { useEffect, useState } from "react"

import { gsap } from "gsap"
import BgVideo from "../assets/video/gmg-background-video.mp4"
import { MobileNav } from "./MobileNav"

export const Top = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    window.onload = function () {
      gsap.from("#top", {
        opacity: 0,
        duration: "0.6",
        delay: "1.4",
      })

      gsap.from("#top-title", {
        opacity: 0,
        y: "-80",
        duration: "0.6",
        delay: 2,
      })

      gsap.from("#top-subtitle", {
        opacity: 0,
        y: "80",
        duration: "0.6",
        delay: 2,
      })
    }

    if (open) {
      document.querySelector("body").classList.add("overflow-hidden")
    } else {
      document.querySelector("body").classList.remove("overflow-hidden")
    }
  })

  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "gmg-logo.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <div id="top" className="flex flex-col justify-end w-screen h-2/3vh">
      <video
        className="absolute inset-0 w-screen h-2/3vh object-cover"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={BgVideo} type="video/mp4" />
      </video>
      <div className="absolute inset-0 w-full h-2/3vh bg-blue-900 bg-opacity-10" />
      <div
        id="top-title"
        className="fixed z-40 top-0 inset-0 h-fit-content flex items-center justify-between bg-white px-6 py-2 xl:px-16 lg:py-4"
      >
        <h1 className="flex flex-col">
          {/* <span className="text-2xl md:text-3xl font-semibold">GMG</span>
          <span className="text-sm md:text-base">
            グレースメディカルグループ
          </span>
          <div className="hidden lg:block mt-2">
            <div className="w-full h-1 bg-primary-blue" />
            <div className="w-full h-1 bg-primary-red" />
            <div className="w-full h-1 bg-primary-blue" />
          </div> */}
          <img className="w-56 lg:w-72" src={data.logo.publicURL} alt="" />
        </h1>
        <button
          id="menuBtn"
          onClick={() => setOpen(!open)}
          className={`${
            open ? "open" : ""
          } z-40 flex flex-col items-center justify-center gap-y-1.5 lg:hidden`}
        >
          <div className={`bg-gray-400 w-6 h-0.5`} />
          <div className={`bg-gray-400 w-6 h-0.5`} />
        </button>
        <nav className="hidden lg:block">
          <ul className="flex items-center text-sm xl:text-base lg:gap-x-8 xl:gap-x-12 transform translate-y-2">
            <li className="group">
              <button
                className="text-sm text-gray-500 font-medium group-hover:opacity-80 transform duration-300"
                onClick={() => scrollTo("#greeting")}
              >
                グレースメディカルグループとは
              </button>
              <div className="w-full h-1 mt-2 bg-gray-300 rounded-full opacity-0 group-hover:opacity-100 transform duration-300" />
            </li>
            {/* <li className="group">
              <button
                className="text-sm text-gray-500 font-medium group-hover:opacity-80 transform duration-300"
                onClick={() => scrollTo("#concept-movie")}
              >
                紹介動画
              </button>
              <div className="w-full h-1 mt-2 bg-gray-300 rounded-full opacity-0 group-hover:opacity-100 transform duration-300" />
            </li> */}
            <li className="group">
              <button
                className="text-sm text-gray-500 font-medium group-hover:opacity-80 transform duration-300"
                onClick={() => scrollTo("#banner01")}
              >
                医療を紡ぐ01
              </button>
              <div className="w-full h-1 mt-2 bg-gray-300 rounded-full opacity-0 group-hover:opacity-100 transform duration-300" />
            </li>
            <li className="group">
              <button
                className="text-sm text-gray-500 font-medium group-hover:opacity-80 transform duration-300"
                onClick={() => scrollTo("#banner02")}
              >
                医療を紡ぐ02
              </button>
              <div className="w-full h-1 mt-2 bg-gray-300 rounded-full opacity-0 group-hover:opacity-100 transform duration-300" />
            </li>
          </ul>
        </nav>
        {/* <nav className="text-sm lg:hidden">
          <ul className="flex flex-col gap-y-8">
            <li>
              <button>グレースメディカルグループとは</button>
            </li>
          </ul>
        </nav> */}
      </div>
      <div className="h-2/3vh flex flex-col justify-end">
        <h2
          id="top-subtitle"
          className="leading-loose text-white font-medium text-2xl md:text-left ml-auto xl:text-4xl xl:mb-8 p-8 pb-10 xl:p-16"
        >
          "Be With Customers"
          <br />
          <span className="text-2xl xl:text-3xl">
            お客様に寄り添った医療サービスを
          </span>
        </h2>
      </div>
      <MobileNav open={open} setOpen={setOpen} />
    </div>
  )
}
