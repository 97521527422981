import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect } from "react"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export const Banner02 = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger)
    }

    gsap.from(".banner02-title", {
      opacity: 0,
      y: "80",
      duration: "1",
      scrollTrigger: {
        trigger: ".banner02-title",
        start: "50% bottom",
      },
    })
    gsap.from(".banner02-image-1", {
      opacity: 0,
      y: "16",
      duration: "1.6",
      scrollTrigger: {
        trigger: ".banner02-image-1",
        start: "top bottom",
      },
    })

    gsap.from(".banner02-text-1", {
      opacity: 0,
      y: "80",
      duration: "1",
      scrollTrigger: {
        trigger: ".banner02-text-1",
        start: "50% bottom",
      },
      stagger: {
        each: "0.2",
      },
    })
    gsap.from(".banner02-image-2", {
      opacity: 0,
      y: "16",
      duration: "1.6",
      scrollTrigger: {
        trigger: ".banner02-image-2",
        start: "top bottom",
      },
    })

    gsap.from(".banner02-text-2", {
      opacity: 0,
      y: "80",
      duration: "1",
      scrollTrigger: {
        trigger: ".banner02-text-2",
        start: "50% bottom",
      },
      stagger: {
        each: "0.2",
      },
    })
    gsap.from(".banner02-text-3", {
      opacity: 0,
      y: "80",
      duration: "1",
      scrollTrigger: {
        trigger: ".banner02-text-3",
        start: "50% bottom",
      },
      stagger: {
        each: "0.2",
      },
    })

    gsap.from(".banner02-link", {
      opacity: 0,
      y: "80",
      duration: "1",
      scrollTrigger: {
        trigger: ".banner02-link",
        start: "50% bottom",
      },
      stagger: {
        each: "0.2",
      },
    })
  })
  return (
    <section id="banner02" className="mt-12 mb-6 py-8 md:pb-0 xl:mt-56">
      <div className="md:flex items-center md:mb-16 lg:gap-x-16 xl:gap-x-32">
        <div className="md:w-1/2 xl:w-fit-content xl:ml-auto">
          <h3 className="banner02-title px-6 flex flex-col gap-y-4 md:mb-12 lg:pl-8">
            <span className="text-blue-900 opacity-50 text-sm lg:text-lg">
              医療を紡ぐ02
            </span>
            <span className="text-lg leading-8 lg:text-2xl lg:leading-sm xl:text-3xl xl:leading-loose">
              関わるすべての方を
              <br />
              笑顔にできるような
              <br />
              組織作り。
              <br />
            </span>
          </h3>
          <p className="banner02-text-1 hidden text-sm leading-sm px-6 md:block lg:text-base lg:leading-base lg:px-8 ">
            GMGでは利用者様はもちろんの
            <br />
            ことですが、スタッフも健康かつ
            <br />
            笑顔で働ける社内環境を
            <br />
            整えております。
            <br />
            <br />
          </p>
          <p className="banner02-text-1 hidden text-sm leading-sm px-6 md:block lg:text-base lg:leading-base lg:px-8 ">
            医師、看護師、サポートスタッフ
            <br />
            など多様な職種が揃い、
            <br />
            すべてのスタッフが一つのチーム
            <br />
            として活躍できる組織を
            <br />
            目指しています。
            <br />
          </p>
        </div>
        <div className="banner02-image-1 w-3/4 ml-auto my-8 md:w-1/2 md:my-0 md:ml-0">
          <StaticImage
            className="object-cover object-top w-full h-full"
            src="../assets/img/banner-02-1.jpg"
            alt="医療を紡ぐ02 画像"
            placeholder="blurred"
            quality="100%"
          />
        </div>
        <div className="px-6 md:hidden">
          <p className="banner02-text-2 text-sm leading-sm">
            GMGでは利用者様はもちろんの
            <br />
            ことですが、スタッフも健康かつ
            <br />
            笑顔で働ける社内環境を
            <br />
            整えております。
            <br />
            <br />
          </p>
          <p className="banner02-text-2 text-sm leading-sm">
            医師、看護師、サポートスタッフ
            <br />
            など多様な職種が揃い、
            <br />
            すべてのスタッフが一つのチーム
            <br />
            として活躍できる組織を
            <br />
            目指しています。
            <br />
          </p>
        </div>
      </div>
      <div className="md:flex items-center gap-x-8 xl:gap-x-32">
        <div className="banner02-image-2 md:w-1/2 lg:w-7/12 xl:max-w-2xl xl:mx-auto">
          <div className="w-2/3 ml-auto mt-8 mr-6 md:my-0 md:w-3/5 lg:w-7/12 h-auto">
            <StaticImage
              className="object-cover object-top w-full h-full z-10"
              src="../assets/img/banner-02-2.jpeg"
              alt="医療を紡ぐ02-2 画像"
              placeholder="blurred"
              quality="100%"
            />
          </div>
          <div className="w-2/3 -mt-6 mb-8 md:mb-0 md:ml-0 md:w-3/5 lg:w-7/12 h-auto">
            <StaticImage
              className="object-cover object-center w-full h-72"
              src="../assets/img/banner-01-2.jpeg"
              alt="医療を紡ぐ02-3 画像"
              placeholder="blurred"
              quality="100%"
            />
          </div>
        </div>
        <div className="px-6 md:w-1/2 md:pl-0 lg:w-5/12 lg:pr-8">
          <p className="banner02-text-3 text-sm leading-sm lg:text-base lg:leading-base ">
            これからも地域に貢献し、
            <br />
            ご利用者様から頂ける「ありがとう」の言葉を
            <br />
            励みにより良いサービスを提供し続けたいと
            <br />
            考えております。
            <br />
            <br />
          </p>
          <p className="banner02-text-3 text-sm leading-sm lg:text-base lg:leading-base mb-8">
            GMGでは同じ志をもって取り組んでいただける
            <br />
            たくさんの仲間を募集しています。
            <br />
          </p>
          <div className="banner02-link">
            <a
              className="mb-4 block text-center font-medium rounded-full bg-primary-blue text-white w-full xl:w-2/3 py-4 text-sm lg:text-base border-2 border-blue-900 hover:bg-white hover:text-blue-900 transform duration-300"
              href="https://grace-dc.com/career/"
              target="_blank"
              rel="noreferrer"
            >
              医療法人社団 慶実会の採用情報を見る
            </a>
            <a
              className="b-4 block text-center font-medium rounded-full bg-primary-blue text-white w-full xl:w-2/3 py-4 text-sm lg:text-base border-2 border-blue-900 hover:bg-white hover:text-blue-900 transform duration-300"
              href="https://gmg-keiikukai.com/careers/"
              target="_blank"
              rel="noreferrer"
            >
              医療法人社団 慶育会の採用情報を見る
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
